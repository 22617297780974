import { RunCommandForm } from "./Commands.type";

interface ValidationErrors {
  hours?: string;
  minutes?: string;
}

export const validate = (values: RunCommandForm): ValidationErrors => {
  let errors: ValidationErrors = {};

  const { hours, minutes } = values;

  if (hours && (hours < 0 || hours > 23)) {
    errors = {
      ...errors,
      hours: "0 < hours < 23",
    };
  }

  if (minutes && (minutes < 0 || minutes > 59)) {
    errors = {
      ...errors,
      minutes: "0 < minutes < 59",
    };
  }

  return errors;
};
