import { useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { FormApi } from "final-form";
import useCommon from "../../hooks/useCommon";
import { useApi, useFetcher } from "../../hooks/useApi";
import CommandCard from "./components/CommandCard";
import { CommandType, RunCommandForm } from "./Commands.type";
import { CommandCardsContainer } from "./Command.styled";

const Commands = () => {
  const fetcher = useFetcher();
  const { setBreadCrumbs } = useCommon();
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { data: commands } = useSWR("/settings/console-command", fetcher);

  const onSubmit = async (
    values: RunCommandForm,
    form: FormApi,
    command_name: string
  ) => {
    const reformatData = {
      command_name,
      ...values,
    };

    try {
      await api.post(`/settings/console-command`, reformatData);
      form.reset();

      return enqueueSnackbar("The command was successfully launched!", {
        variant: "success",
      });
    } catch (e) {
      return enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };

  const initialValue = {
    hours: null,
    minutes: null,
  };

  useEffect(() => {
    setBreadCrumbs?.([{ label: "Commands" }]);
  }, [setBreadCrumbs]);

  return (
    <CommandCardsContainer>
      {commands &&
        commands.map((command: CommandType, index: number) => (
          <CommandCard
            key={index}
            name={command.name}
            title={command.title}
            description={command.description}
            initialValue={initialValue}
            onSubmit={onSubmit}
          />
        ))}
    </CommandCardsContainer>
  );
};

export default Commands;
