import { useState, FC } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { FormApi } from "final-form";
import { Field } from "react-final-form";
import DefaultForm from "../../../../components/form/DefaultForm";
import { CommandCardTypes } from "./CommandCard.type";
import { RunCommandForm } from "../../Commands.type";
import {
  CommandCardContainer,
  CommandCardFieldsContainer,
} from "./CommandCard.styled";
import { validate } from "../../validate";
import ErrorLabel from "../../../../components/form/ErrorLabel";

const CommandCard: FC<CommandCardTypes> = ({
  name,
  title,
  description,
  initialValue,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: RunCommandForm, form: FormApi) => {
    setIsSubmitting(true);
    await onSubmit(values, form, name);
    setIsSubmitting(false);
  };

  return (
    <CommandCardContainer>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography variant="h5" component="span">
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" component="span">
          {description}
        </Typography>
      </Box>

      <DefaultForm<RunCommandForm>
        data={initialValue}
        onSubmit={handleSubmit}
        validate={validate}
      >
        <CommandCardFieldsContainer>
          <Field name="hours">
            {({ input, meta }) => (
              <Box>
                <TextField
                  {...input}
                  sx={{ width: "105px" }}
                  label="hours"
                  type="number"
                  variant="standard"
                  InputProps={{ inputProps: { min: 0, max: 23 } }}
                />
                <ErrorLabel error={meta.error} touched={meta.touched} />
              </Box>
            )}
          </Field>

          <Field name="minutes">
            {({ input, meta }) => (
              <Box>
                <TextField
                  {...input}
                  sx={{ width: "105px" }}
                  label="minutes"
                  type="number"
                  variant="standard"
                  InputProps={{ inputProps: { min: 0, max: 59 } }}
                />
                <ErrorLabel error={meta.error} touched={meta.touched} />
              </Box>
            )}
          </Field>
        </CommandCardFieldsContainer>

        <Button
          sx={{ textTransform: "none" }}
          variant="outlined"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Run command"}
        </Button>
      </DefaultForm>
    </CommandCardContainer>
  );
};

export default CommandCard;
