import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { ErrorLabelProps } from "./ErrorLabel.types";
import theme from "../../../theme";

const ErrorLabel: FC<ErrorLabelProps> = ({ touched, error }) => {
  return touched && error ? (
    <Box>
      <Typography
        sx={{ color: theme.palette.error.main }}
        variant="body2"
        component="span"
      >
        {error}
      </Typography>
    </Box>
  ) : (
    <></>
  );
};

export default ErrorLabel;
