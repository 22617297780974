import { Box, styled } from "@mui/material";

export const CommandCardContainer = styled(Box)(() => ({
  width: "300px",
  border: "1px solid #EDEFF5",
  borderRadius: "8px",
  background: "#FFF",
  padding: "20px",
}));

export const CommandCardFieldsContainer = styled(Box)(() => ({
  margin: "20px 0",
  display: "flex",
  gap: "32px",
}));
